import React, { useState } from 'react';
import { FormLabel, TextField, FormHelperText, Typography } from '@mui/material';

import { getErrorMessage } from '@api/handleApiError';
import { useToast } from '@context/toastContext';
import { BookingSteps } from '@utils/constants';
import { checkPhoneNumberAPI, registerPatientAPI } from '@api/main';
import { FormControlWrapper, InputWrapper } from '@components/Input';
import { isNaN } from 'lodash';
import AuthLayout from '@components/AuthLayout';
import { signUpWithFirebase } from 'src/config/firebaseConfig';
import { LoadingButton } from '@mui/lab';

const Login = ({ clinicInfo, setAuthData, setCurrentStep }) => {
  const showToast = useToast();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const phone = phoneNumber.replace(' ', '').trim();
      // The phone number have to start with 0 and have 10 digits
      if (phone.length !== 10 || !phone.startsWith('0') || isNaN(phone)) {
        setErrorMessage('Số điện thoại phải bắt đầu bằng số 0 và có 10 chữ số');
        return;
      }
      const number = `+84${Number(phone)}`;

      const result = await checkPhoneNumberAPI({
        phoneNumber: number
      });
      if (result.data.existed) {
        if (result.data.isWaitingForPassword) {
          // the in-app browser of MBbank (web.drcloud.vn) and then go back to app
          // return null;
        } else {
          setAuthData({ phoneNumber: number });
          setCurrentStep(BookingSteps.login2);
        }
      } else {
        const res = await registerPatientAPI({ phoneNumber: number });
        const auth = await signUpWithFirebase(number);
        let errMessage = '';
        if (!auth?.errorCode) {
          setAuthData({ confirm: auth.confirm, userId: res.data.userId });
          setCurrentStep(BookingSteps.register1);
        } else if (auth.errorCode === 'auth/too-many-requests') {
          errMessage = 'Bạn đã yêu cầu xác thực quá nhiều. Vui lòng thử lại sau!';
        } else if (auth.errorCode === 'auth/missing-client-identifier') {
          errMessage = 'Thiếu mã định danh. Vui lòng thử lại sau!';
        } else {
          errMessage = 'Xác thực thất bại';
        }
        if (!!errMessage) {
          showToast({
            type: 'error',
            message: errMessage
          });
        }
      }
    } catch (error) {
      showToast({
        type: 'error',
        message: getErrorMessage(error)
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AuthLayout clinicInfo={clinicInfo}>
      <form style={{ display: 'flex', flexDirection: 'column', height: '100%' }} onSubmit={onSubmit}>
        <FormControlWrapper required style={{ width: '100%', marginTop: '2rem' }} error={!!errorMessage}>
          <FormLabel>Số điện thoại</FormLabel>
          <InputWrapper hasBorder>
            <TextField
              autoFocus
              type="number"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              fullWidth
            />
          </InputWrapper>
          {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControlWrapper>

        <LoadingButton
          id="recaptcha-container"
          sx={{ marginTop: '2rem', paddingY: '10px' }}
          variant="contained"
          onClick={onSubmit}
          loading={isSubmitting}
          disabled={!phoneNumber}
        >
          Tiếp theo
        </LoadingButton>
      </form>

      <Typography variant="h6" sx={{ position: 'absolute', bottom: '1rem', textAlign: 'center', paddingRight: 4 }}>
        <Typography variant="span" sx={{ color: '#F96363' }}>
          *
        </Typography>
        Hệ thống hiện đang hỗ trợ các nhà mạng: Viettel, Vinaphone, Mobifone, Vietnamobile và Itel
      </Typography>
    </AuthLayout>
  );
};

export default Login;
